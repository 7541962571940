import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Table, Space, Row, Button,
    Col, Typography, Layout,
    DatePicker, Spin, Input,
    Divider, List, Modal, Radio,
    Drawer, Select, InputNumber
} from 'antd';
import PageHeader from '../../comps/pageHeader';
import { GetSingleClient, UpdateClient, getBannerInfo, getClientList, setClientToUpdateId } from './cactusProSlice';
import { createCodes, setClientIdToGenerateCodes } from './activationCodesSlice';
import moment from 'moment';
import {
    SearchOutlined, FilterFilled,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';


const { Text, Paragraph, Link } = Typography;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function ClientList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [timeout, setTimeoutVal] = useState(0);

    // Used to display the name when the user uses the name filter
    // since the onChange event only triggers every 300 ms after user
    // stops writing characters inside the name filter input
    const [nameDisplay, setNameDisplay] = useState("");
    const [emailDisplay, setEmailDisplay] = useState("");

    const [drawerOpen, setDrawerOpen] = useState(false);

    const [updateData, setUpdateData] = useState({
        id: null,
        name: "",
        email: "",
        type: null,
        password: null,

    })
    // Used to store the currently selected school's activation links
    // and render them on a modal
    const [selectedLinks, setSelectedLinks] = useState([]);
    const [isLinkModalOpen, setIsLinkedModalOpen] = useState(false);
    const [isGenerationModalOpen, setIsGenerationModalOpen] = useState(false)
    const [clientsFilter, setClientsFilter] = useState({
        name: "",
        email: "",
        pageLength: 10,
        page: 1,
        startDate: null,
        endDate: null,
        type: null,

    });

    const [generationSecondModuleSelectOptions, setGenerationSecondModuleSelectOptions] = useState([
        { label: "1", value: 1 },
        { label: "2", value: 2 },
        { label: "3", value: 3 },
        { label: "4", value: 4 },
        { label: "5", value: 5 },
        { label: "6", value: 6 }
    ])
    const [generationData, setGenerationData] = useState({
        displayName: "",
        clientPro: "",
        numberOfCodes: 1,
        modules: [1, 1],
    })

    const clientList = useSelector((state) => state.cactusPro.fetchClientsSuccess);
    const isClientsLoading = useSelector((state) => state.cactusPro.isFetchingClients);
    const bannerInfo = useSelector((state) => state.cactusPro.fetchBannerInfoSuccess);
    const isBannerLoading = useSelector((state) => state.cactusPro.isFetchingBannerInfo);
    const singleClient = useSelector((state) => state.cactusPro.fetchSingleClientSuccess);
    const isLoadingUpdate = useSelector((state) => state.cactusPro.isUpdatingClient);
    const generationSuccess = useSelector((state) => state.activationCodes.generateCodesSuccess)
    const isGeneratingCodes = useSelector((state) => state.activationCodes.isGeneratingCodes);



    useEffect(() => {
        dispatch(getBannerInfo());
        dispatch(getClientList(clientsFilter));
    }, [clientsFilter]);

    useEffect(() => {
        if (!singleClient) {
            return;
        }
        const clientData = singleClient.data;
        setUpdateData({ ...updateData, id: clientData._id, name: clientData.name, email: clientData.email, type: clientData.type });
    }, [singleClient]);

    useEffect(() => {

        // So that you can only enter valid ranges when generating codes,
        // ex : 3-1 is not a valid range
        // 1-4 is a valid range
        let firstModule = generationData.modules[0];
        let options = [];
        for (let i = firstModule; i < 7; i++) {
            options.push({ label: i.toString(), value: i });
        }
        setGenerationSecondModuleSelectOptions(options);
        if (generationData.modules[1] < firstModule)
            setGenerationData({ ...generationData, modules: [firstModule, firstModule] });

    }, [generationData.modules]);

    useEffect(() => {
        if (!generationSuccess) 
            return;
        setIsGenerationModalOpen(false);
        setGenerationData({
            displayName: "",
            clientPro: "",
            numberOfCodes: 1,
            modules: [1, 1],
        })
        dispatch(getBannerInfo());
        dispatch(getClientList(clientsFilter));
    }, [generationSuccess])

    const timedNameSearch = (data) => {
        setNameDisplay(data);
        if (timeout) clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            setClientsFilter({ ...clientsFilter, name: data });
        }, 300));
    }

    const timedEmailSearch = (data) => {
        setEmailDisplay(data);
        if (timeout) clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            setClientsFilter({ ...clientsFilter, email: data });
        }, 300));
    }

    const onChangeDates = (e) => {
        if (e) {
            setClientsFilter({ ...clientsFilter, startDate: e[0]?.format(), endDate: e[1]?.format() });
        }
        else {
            setClientsFilter({ ...clientsFilter, startDate: null, endDate: null });
        }
    }

    const onUpdateUser = () => {
        if (updateData.password) {
            Modal.confirm({
                title: "Attention !",
                content: "Vous allez modifier le mot de passe de ce client, veuillez vous assurer de l'avoir noté et de l'envoyer au client.",
                cancelText: "Annuler",
                onOk() {
                    dispatch(UpdateClient(updateData));
                    setUpdateData({ ...updateData, password: null });
                    setDrawerOpen(false);
                    dispatch(getBannerInfo());
                    dispatch(getClientList(clientsFilter));
                },
                onCancel() {
                    setUpdateData({ ...updateData, password: null });
                }

            })
            return;
        }
        dispatch(UpdateClient(updateData));
        setUpdateData({ ...updateData, password: null });
        setDrawerOpen(false);
        // Added a timeout so the server has time to update the data
        setTimeout(() => {
            dispatch(getBannerInfo());
            dispatch(getClientList(clientsFilter));
        }, 500);
    }

    const onGenerateCodes = () => {
        if (isGeneratingCodes)
            return;
        dispatch(createCodes(generationData));
    }

    const showLinksModal = (data) => {
        setSelectedLinks(data);
        setIsLinkedModalOpen(true);
    }

    const closeModal = () => {
        setIsLinkedModalOpen(false);
    }

    const onCloseDrawer = () => {
        setDrawerOpen(false);
    }

    const getColumnNameSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    placeholder={`Nom d'école`}
                    value={nameDisplay}
                    onChange={(e) => timedNameSearch(e.target.value)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                    onPressEnter={() => dispatch(getClientList(clientsFilter))}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(getClientList(clientsFilter))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => { setClientsFilter({ ...clientsFilter, name: null }); setNameDisplay("") }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const getColumnEmailSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >

                <Input
                    placeholder={`ecole@mail.com`}
                    value={emailDisplay}
                    onChange={(e) => timedEmailSearch(e.target.value)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                    onPressEnter={() => dispatch(getClientList(clientsFilter))}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(getClientList(clientsFilter))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => { setClientsFilter({ ...clientsFilter, email: null }); setEmailDisplay("") }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const getColumnFilterTypeProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group onChange={(e) => setClientsFilter({ ...clientsFilter, type: e.target.value })} value={clientsFilter.type}>
                    <Space direction="vertical">
                        <Radio value={"school"}>Ecoles</Radio>
                        <Radio value={"library"}>Bibliothèques</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const columns = [
        {
            title: 'Nom de l\'établissement',
            dataIndex: 'name',
            key: '_id',
            width: "160px",
            ...getColumnNameSearchProps()
        },
        {
            title: 'Adresse Mail',
            dataIndex: 'email',
            key: '_id',
            render: (data) => (<a style={{ color: "#39B13D" }} href={`mailto:${data}`} >{data} </a>),
            width: "160px",
            ...getColumnEmailSearchProps()
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (data) => (<p>{data === "library" ? "Bibliothèque" : "Ecole"}</p>),
            width: "100px",
            ...getColumnFilterTypeProps()
        },
        {
            title: 'Date Ajouté',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (data) => (<p>{moment(data).format("DD/MM/YYYY")}</p>),
            width: "30px",

        },
        {
            title: 'Liens d\'activation',
            dataIndex: 'links',
            key: 'type',
            render: (data) => (<Button
                // icon={<ImportOutlined dir="left" style={{ fontSize: "16px" }} />}
                style={{
                    backgroundColor: "#39B13D",

                    color: "#fff",
                    height: "2.3em",
                    width: 160,
                    fontWeight: "400"
                }}
                onClick={() => showLinksModal(data)}
            >Liens d'activation</Button>),
            width: "40px",
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            render: (id, row) => (
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <a data-id={id} onClick={() => { dispatch(GetSingleClient({ id })); setDrawerOpen(true); }} style={{ color: "#39B13D", fontSize: "16px" }}>
                        Modifier
                    </a>
                    {/* TODO: Remove or add this depending on whether or not there's a route for it */}
                    {/* <Divider type='vertical' />
                    <a data-id={data} onClick={(data) => console.log(data)} style={{ color: "#39B13D", fontSize: "16px" }}>
                        Suspendre
                    </a> */}
                    <Divider type='vertical' />
                    <a data-id={id} onClick={() => { setGenerationData({ ...generationData, displayName: row.name, clientPro: id }); setIsGenerationModalOpen(true); }} style={{ color: "#39B13D", fontSize: "16px" }}>
                        Générer des codes
                    </a>
                </div>),
            width: "150px"

        }

    ];

    const onChangePage = (e) => {
        setClientsFilter({ ...clientsFilter, page: e.current, pageLength: e.pageSize });
    }

    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >

            <PageHeader
                title={"Vue d’ensemble des Clients Pro "}
                breadcrumbItems={["Panneau Clients Pro", "Vue d’ensemble "]}
            />

            <div style={{ display:"flex", flexDirection:"row", justifyContent:"space-between", background: "#FFFFFF", margin: "24px", minHeight: "164px", padding: "24px 128px 24px 128px" }}
            >
                {isBannerLoading ? (<Spin style={{ margin: "auto" }} />) : (
                    <>
                        <div style={{ width:"20vw", display: "flex", flexDirection: "column", alignItems: "center" }} >
                            <h2 style={{ fontSize: "14px", fontWeight: "400" }}>Total de Clients Pro</h2>
                            <p style={{ fontSize: "38px", margin: "0" }}>{bannerInfo?.clientsCount ? bannerInfo.clientsCount : "NaN"}</p>
                        </div>
                        <div style={{ width:"20vw", display: "flex", flexDirection: "column", alignItems: "center" }} >
                            <h2 style={{ fontSize: "14px", fontWeight: "400" }}>Dernier Client Inscrit le</h2>
                            <p style={{ fontSize: "38px", margin: "0" }}>{bannerInfo?.lastClientDate ? moment(bannerInfo.lastClientDate).format("DD/MM/YYYY") : "Aucune date"}</p>
                        </div>
                        <div style={{ width:"20vw", display: "flex", flexDirection: "column", alignItems: "center" }} >
                            <h2 style={{ fontSize: "14px", fontWeight: "400" }}>Nombre total de codes générés</h2>
                            <p style={{ fontSize: "38px", margin: "0" }}>{bannerInfo?.codesCount ? bannerInfo.codesCount : "NaN"}</p>
                        </div></>
                )}

            </div>

            <Row justify={"center"} style={{ background: "#424242", margin: "24px", minHeight: "112px" }}
            >
                <Col span={5} style={{ paddingTop: 10, paddingBottom: 10 }}                >
                    <Space align='center' direction="vertical" size="middle" style={{ display: 'flex', marginTop: "20px", marginBottom: "20px" }}>
                        <Text style={{ color: "white", fontSize: "16px" }}>Ajouter un nouveau client pro : </Text>
                        <Button type='primary'
                            onClick={() => { navigate('/dashboard/pro/clientPro/add') }}
                            style={{ width: "100%", height: "32px" }}>Nouveau Client Pro</Button>
                    </Space>
                </Col>
            </Row>

            <div
                style={{
                    margin: "2em",
                    padding: "1em 2em 1em 2em",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundColor: "#fff"
                }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}>
                    <h3 style={{ fontSize: "16px" }}>Liste des Clients Pro</h3>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "center"
                    }}>
                        <p style={{ marginRight: "10px", fontSize: "15px" }}>Filtrer par date :</p>
                        <RangePicker
                            style={{
                                height: "2.5em"
                            }}
                            onCalendarChange={onChangeDates}
                        />
                    </div>
                </div>
                <Divider style={{ margin: "10px 0px" }} />
                <Table
                    style={{ width: "100%" }}
                    columns={columns}
                    pagination={{
                        total: clientList?.clientsCount,
                        pageSize: clientsFilter.pageLength,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 15, 20, 30, 40, 50],
                        showQuickJumper: true,
                        showTotal: (total, range) => (<>Nombre de clients : {total} </>),
                    }}
                    loading={isClientsLoading}
                    dataSource={clientList?.clients}
                    onChange={onChangePage}
                />
            </div>

            <Drawer
                title={
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                        <h3 style={{ margin: "0px 16px 0px 0px" }}>Modifier Informations Client</h3>
                        <div>
                            <Button style={{ marginRight: "16px" }} onClick={() => setDrawerOpen(false)}>Annuler</Button>
                            <Button type='primary' onClick={onUpdateUser}>{isLoadingUpdate ? <Spin className='proClientSpinner' /> : "Sauvegarder"}</Button>
                        </div>
                    </div>
                }
                width={750}
                placement={"right"}
                closable={true}
                onClose={onCloseDrawer}
                open={drawerOpen}
                key={"right"}
            >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                        <p style={{ margin: "5px 0px" }}>Nom de l’établissement</p>
                        <Input
                            placeholder='Collège'
                            value={updateData.name}
                            onChange={(e) => setUpdateData({ ...updateData, name: e.target.value })}
                            addonAfter={(
                                <Select style={{ width: "80px" }} value={updateData.type} onChange={(e) => setUpdateData({ ...updateData, type: e })}>
                                    <Option value="school">École</Option>
                                    <Option value="library">Bibliothèque</Option>
                                </Select>
                            )}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>

                        <p style={{ margin: "5px 0px" }}>Addresse Mail</p>
                        <Input
                            placeholder='mail@gmail.com'
                            value={updateData.email}
                            onChange={(e) => setUpdateData({ ...updateData, email: e.target.value })}
                        />
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "32px" }}>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>
                        <p style={{ margin: "5px 0px" }}>Mot de Passe</p>
                        <Input
                            placeholder='Mot de passe'
                            value={updateData.password}
                            rules={[
                                {
                                    required: true,
                                    // type: "regexp",
                                    pattern: new RegExp(/(?=^.{8,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/g),
                                    message: 'Veuillez insérer un mot de passe valide',
                                },
                            ]}
                            onChange={(e) => setUpdateData({ ...updateData, password: e.target.value })}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", width: "45%" }}>
                        <p style={{ color: "#00000073", margin: "5px 0px" }}>Le Mot de Passe doit comporter au moins :</p>
                        <ul style={{ paddingLeft: "16px", marginTop: 0 }}>
                            <li style={{ color: "#00000073" }}>De 8 à 12 caractères</li>
                            <li style={{ color: "#00000073" }}>Au moins 1 minuscule</li>
                            <li style={{ color: "#00000073" }}>Au moins 1 majuscule</li>
                            <li style={{ color: "#00000073" }}>Au moins 1 chiffre</li>
                        </ul>
                    </div>
                </div>

            </Drawer>

            <Modal
                title="Génération de Codes d'Accès"
                open={isGenerationModalOpen}
                onCancel={() => setIsGenerationModalOpen(false)}
                width={600}
                okText={isGeneratingCodes ? <Spin className='proClientSpinner' /> : "Générer Code(s)"}
                cancelText="Annuler"
                onOk={onGenerateCodes}
            >
                <Divider style={{ margin: "3px 0px" }} />
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "start" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <p>Générer pour : {generationData.displayName}</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <p style={{ marginRight: "10px" }}>Nombre de codes :</p>
                        <InputNumber value={generationData.numberOfCodes} min={1} onChange={(e) => setGenerationData({ ...generationData, numberOfCodes: e })} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <p style={{ marginRight: "10px" }}>Modules entre : </p>
                        <Select
                            value={generationData.modules[0]}
                            onChange={(e) => setGenerationData({ ...generationData, modules: [e, generationData.modules[1]] })}
                            style={{ width: 80 }}
                            options={[
                                { label: "1", value: 1 },
                                { label: "2", value: 2 },
                                { label: "3", value: 3 },
                                { label: "4", value: 4 },
                                { label: "5", value: 5 },
                                { label: "6", value: 6 }
                            ]}
                        />
                        <p style={{ margin: "0px 12px" }}> et </p>
                        <Select
                            value={generationData.modules[1]}
                            onChange={(e) => setGenerationData({ ...generationData, modules: [generationData.modules[0], e] })}
                            style={{ width: 80 }}
                            options={generationSecondModuleSelectOptions}
                        />

                    </div>
                </div>
                <Divider style={{ margin: "3px 0px" }} />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <ExclamationCircleOutlined style={{ color: "#FAAD14", fontSize: "32px", marginRight: "5px" }} />
                    <p>Veuillez vérifier les informations de votre commande avant de valider !</p>
                </div>
                <Divider style={{ margin: "3px 0px" }} />

            </Modal>
            <Modal title="Liens d'activation Cactus" width={600} open={isLinkModalOpen} onCancel={() => closeModal()} footer={(<Button onClick={() => closeModal()} style={{ backgroundColor: '#39B13D', color: "#fff" }}>Fermer</Button>)} >
                <Divider style={{ margin: "18px 0px 18px 0px" }} />
                <List dataSource={selectedLinks} renderItem={(item) => (
                    <List.Item style={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                        <h4 style={{ margin: "0px" }}>Modules de {item.split("__")[1][0]} à {item.split("__")[1][1]} </h4>
                        <Text href={item} copyable="true" >{item}</Text>
                    </List.Item>
                )} />

            </Modal>

        </Content>
    )
}