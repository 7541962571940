import { configureStore } from '@reduxjs/toolkit';
import SignInReducer from '../features/sigIn/signInSlice';
import adminsReducer from '../features/admins/adminsSlice';
import adminReducer from '../features/admin/singleAdminSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import moduleReducer from '../features/modules/moduleSlice';
import lessonsReducer from '../features/lessons/lessonsSlice';
import lessonReducer from '../features/lesson/singleLessonSlice';
import addExoReducer from '../features/exercices/addExerciceSlice';
import addCommonQstReducer from '../features/exercices/addCommonQstSlice';
import addNonCommonQstReducer from '../features/exercices/addNonCommonQstSlice ';
import exerciceReducer from '../features/exercices/singleExerciceSlice';
import questionsReducer from '../features/exercices/questionsSlice';
import activationCodesReducer from '../features/cactuspro/activationCodesSlice';
import cactusProReducer from '../features/cactuspro/cactusProSlice';
import itemsReducer from '../features/avatar/itemsSlice';
import addItemReducer from '../features/avatar/addItemSlice';
import singleItemReducer from '../features/avatar/singleItemSlice';
import appPramsReducer from '../features/appParams/appPramsSlice';
import packsReducer from '../features/shop/packsListSlice'
import singlePackReducer from '../features/shop/singlePackSlice'
import addPackReducer from '../features/shop/addPackSlice'
import pormoCodesReducer from '../features/promoCode/promoCodesListSlice'
import addPromoCodeReducer from '../features/promoCode/AddPromoCodeSlice'
import defisReducer from '../features/defis/defisListSlice'
import singleDefiReducer from '../features/defi/singleDefiSlice'
import sendNotifReducer from '../features/notifications/notificationSlice'
import usersReducer from '../features/users/usersListSlice'
import userReducer from '../features/user/userSlice'
import userCountReducer from '../features/statistics/statisticsSlice'
import SignUpChartSliceReducer from '../features/statistics/signupChartSlice'
import modulesAchvReducer from '../features/statistics/modulesAchvSlice'
import moduleProgressReducer from '../features/statistics/moduleProgLessonSlice'
import defisChartDataReducer from '../features/statistics/defisChartSlice'
import onboardingdatasReducer from '../features/statistics/onboardingdatasSlice'
import transactionscountReducer from '../features/finance/financeSlice'
import revenueChartDataReducer from '../features/finance/revenueChartSlice'
import transactionsReportDataReducer from '../features/finance/reportFinanceSlice'

export const store = configureStore({
  reducer: {
    signIn: SignInReducer,
    admins: adminsReducer,
    admin: adminReducer,
    dashboard: dashboardReducer,
    module: moduleReducer,
    lessons: lessonsReducer,
    lesson: lessonReducer,
    addCommonQst: addCommonQstReducer,
    addNonCommonQst: addNonCommonQstReducer,
    addExo: addExoReducer,
    exercice: exerciceReducer,
    questions: questionsReducer,
    cactusPro: cactusProReducer,
    activationCodes: activationCodesReducer,
    items: itemsReducer,
    addItem: addItemReducer,
    singleItem: singleItemReducer,
    appPrams: appPramsReducer,
    packs: packsReducer,
    singlePack: singlePackReducer,
    addPack: addPackReducer,
    pormoCodes: pormoCodesReducer,
    addPromoCode: addPromoCodeReducer,
    defis: defisReducer,
    singleDefi: singleDefiReducer,
    sendNotif: sendNotifReducer,
    users: usersReducer,
    user: userReducer,
    userscount: userCountReducer,
    signupChartData: SignUpChartSliceReducer,
    modulesAchv: modulesAchvReducer,
    moduleProgress: moduleProgressReducer,
    defisChartData: defisChartDataReducer,
    onboardingdatas: onboardingdatasReducer,
    transactionscount: transactionscountReducer,
    revenueChartData: revenueChartDataReducer,
    transactionsReportData: transactionsReportDataReducer,
  },
});
