import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Form, Button, Typography,
    Layout, Spin, Input,
    Divider, Modal, Select,
} from 'antd';
import PageHeader from '../../comps/pageHeader';
import { CreateClient, setCreateClientSuccess } from './cactusProSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const { Content } = Layout;
const { Option } = Select;

export default function AddProClient() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [creationData, setCreationData] = useState({
        name: "",
        email: "",
        type: "school",
        password: ""
    });


    const clientCreationSuccess = useSelector((state) => state.cactusPro.createClientSuccess);
    const isCreatingClient = useSelector((state) => state.cactusPro.isCreatingClient);

    useEffect(() => {
        if (!clientCreationSuccess)
        {
            return;
        }
        Modal.success({
            title:"Succès",
            content:"Client ajouté avec succès",
            onOk() {
                dispatch(setCreateClientSuccess(null));
                navigate("/dashboard/pro/clientPro/list");
            }
        })
    }, [clientCreationSuccess])

    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader
                title={"Ajouter un nouveau Client Pro"}
                breadcrumbItems={["Panneau Clients Pro", "Ajouter un Client Pro"]}
            />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#FFFFFF",
                    margin: "2em",
                    padding: "1em 2em 1em 2em"
                }}
            >
                <div style={{ width: "100%", alignItems: "flex-start" }}>
                    <h2 style={{ fontSize: "18px", marginLeft: "0" }}>Informations pertinentes</h2>
                </div>
                <Divider style={{ margin: "10px 0px 10px 0px" }} />
                <div style={{ width: "40%", alignItems: "center" }}>
                    <Form
                        name="Inscription"
                        layout='vertical'
                        onFinish={() => { dispatch(CreateClient(creationData)); }}
                    >
                        <Form.Item
                            label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer le nom de l’établissement</p>}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Veuillez insérer le nom de l\'établissement.',
                                },
                            ]}
                        >
                            <Input
                                placeholder='Collège'
                                onChange={(e) => setCreationData({ ...creationData, name: e.target.value })}
                                addonAfter={(
                                    <Select value={creationData.type} onChange={(e) => setCreationData({ ...creationData, type: e })}>
                                        <Option value="school">École</Option>
                                        <Option value="library">Bibliothèque</Option>
                                    </Select>
                                )}
                            />
                        </Form.Item>
                        <Form.Item
                            label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Entrer une adresse mail</p>}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g),
                                    message: 'Veuillez insérer une adresse mail valide',
                                },
                            ]}
                        >
                            <Input placeholder='address@mail.com' onChange={(e) => setCreationData({ ...creationData, email: e.target.value })} />
                        </Form.Item>
                        <Form.Item
                            label={<p style={{ fontWeight: "400", margin: "0px 0px 0px 0px" }}>Ajouter un Mot de Passe</p>}
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    // type: "regexp",
                                    pattern: new RegExp(/(?=^.{8,12}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/g),
                                    message: 'Veuillez insérer un mot de passe valide',
                                },
                            ]}
                        >
                            <Input type='password' placeholder='Mot de Passe' onChange={(e) => setCreationData({ ...creationData, password: e.target.value })} />
                        </Form.Item>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ justifyContent: "start" }}>
                                <p style={{ color: "#00000073" }}>Le Mot de Passe doit comporter au moins :</p>
                                <ul style={{ paddingLeft: "16px" }}>
                                    <li style={{ color: "#00000073" }}>De 8 à 12 caractères</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 minuscule</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 majuscule</li>
                                    <li style={{ color: "#00000073" }}>Au moins 1 chiffre</li>
                                </ul>
                            </div>
                            <Divider style={{ margin: "10px 0px 10px 0px" }} />
                            <p>Vérifier les informations avant de confirmer.</p>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    {isCreatingClient ? <Spin className='proClientSpinner' /> : "Ajouter"}
                                </Button>
                            </Form.Item>
                        </div>

                    </Form>
                </div>

            </div>
        </Content>
    )
}