import { Image, Typography } from 'antd';

export default function HorizontalDialog({ text }) {
    return (
        <>
            <Image preview={false} width={"150px"} src='Lilly.png' />
            <div className='horizontalDialog'>
                <Image preview={false} src='DialogBubbleHorizontal.svg' />
                <p
                    style={{
                        position: "absolute",
                        fontWeight: 600,
                        lineHeight:"normal",
                        fontSize: "1vw",
                        color: "#212121",
                    }}>
                    {text}
                </p>
            </div>
        </>
    )
}