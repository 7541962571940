import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Badge, Button, DatePicker, Divider, Input,
    Layout, Radio, Select,
    Slider, Space, Table, Tooltip
} from "antd";
import {
    SearchOutlined, FilterFilled
} from '@ant-design/icons';
import PageHeader from "../../comps/pageHeader";
import { getClientNames, getCodesList } from "./activationCodesSlice"
import { } from "./cactusProSlice";
import moment from "moment";

const { Content } = Layout;
const { RangePicker } = DatePicker;

export default function ActivationCodes({ props }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [nameOptions, setNameOptions] = useState([{ value: null, label: "Aucune" }]);
    const [timeout, setTimeoutVal] = useState(0);
    const [codeFilterDisplay, setCodeFilterDisplay] = useState("");
    const [filterData, setFilterData] = useState({
        code: "",
        usesLeft: null,
        modules: null,
        clientPro: null,
        page: 1,
        limit: 10,
        startDate: moment("2023/01/01").format(),
        endDate: moment().format(),
    });

    const ClientData = useSelector((state) => state.activationCodes.fetchClientNamesSuccess);
    const CodesList = useSelector((state) => state.activationCodes.fetchCodesSuccess);
    const isLoadingCodesList = useSelector((state) => state.activationCodes.isFetchingCodes)

    useEffect(() => {
        dispatch(getClientNames());
        dispatch(getCodesList(filterData));
    }, [filterData]);

    useEffect(() => {
        if (!ClientData) {
            return;
        }
        if (!ClientData.clientNames)
            return;
        const nameData = ClientData?.clientNames?.map((elem) => { return { value: elem._id, label: elem.name } });
        setNameOptions([{ value: null, label: "Aucune" }].concat(nameData));
    }, [ClientData])




    const timedSearch = (data) => {
        setCodeFilterDisplay(data);
        let baseText = data;
        if (timeout) clearTimeout(timeout);
        setTimeoutVal(setTimeout(() => {
            let text = baseText ? baseText.replaceAll("-", "") : null;
            setFilterData({ ...filterData, code: text });
        }, 300));
    }

    const onChangeDates = (e) => {
        if (e) {
            setFilterData({ ...filterData, startDate: e[0]?.format(), endDate: e[1]?.format() });
        }
        else {
            setFilterData({ ...filterData, startDate: null, endDate: null });
        }
    }

    const onChangePage = (e) => {
        setFilterData({ ...filterData, page: e.current, limit: e.pageSize });
    }

    const getColumnSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Tooltip placement="top" title={"Veuillez insérer un code complet"}>
                    <Input
                        // ref={searchInput}
                        placeholder={`XXXX-XXXX-XXXX-XXXX`}
                        value={codeFilterDisplay}
                        onChange={(e) => timedSearch(e.target.value)}

                        //   onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{
                            marginBottom: 8,
                            display: 'block',
                        }}
                    />
                </Tooltip>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(getCodesList(filterData))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => { setFilterData({ ...filterData, code: null }); setCodeFilterDisplay(null) }}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#627262' : undefined,
                }}
            />
        ),
    });

    const getColumnFilterByModuleProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Slider
                    range
                    defaultValue={[1, 3]}
                    min={1}
                    max={6}
                    value={filterData.modules ? filterData.modules : [1, 1]}
                    onChange={(e) => setFilterData({ ...filterData, modules: e })}
                    marks={{ 1: "1", 2: "2", 3: "3", 4: "4", 5: "5", 6: "6" }}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                        marginBottom: "24px"
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => dispatch(getCodesList(filterData))}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Chercher
                    </Button>
                    <Button
                        onClick={() => setFilterData({ ...filterData, modules: null })}
                        size="small"
                        style={{
                            width: 110,
                        }}
                    >
                        Réinitialiser
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        Fermer
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled
                style={{
                    color: filtered ? '#39B13D' : undefined,
                }}
            />
        ),
    });

    const getColumnFilterStatusProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Radio.Group onChange={(e) => setFilterData({ ...filterData, usesLeft: e.target.value })} value={filterData.usesLeft}>
                    <Space direction="vertical">
                        <Radio value={1}>Non Activés</Radio>
                        <Radio value={0}>Activés</Radio>
                        <Radio value={null}>Tout</Radio>
                    </Space>
                </Radio.Group>
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    Fermer
                </Button>
            </div>),
        filterIcon: (filtered) => (<FilterFilled
            style={{
                color: filtered ? '#39B13D' : undefined,
            }}
        />),
    });

    const columns = [
        {
            title: 'Code d\'accès',
            dataIndex: 'code',
            key: '_id',
            render: (text) => (<p>{text.match(/(.{4})/g).join("-")}</p>),
            width: "800px",
            ...getColumnSearchProps()
        },
        {
            title: 'Modules',
            dataIndex: 'modules',
            key: '_id',
            render: (data) => (<p>{data[0]}-{data[1]} </p>),
            width: "20px",
            ...getColumnFilterByModuleProps()
        },
        {
            title: 'Date généré',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (data) => (<p>{moment(data).format("DD/MM/YYYY")}</p>),
            width: "30px",
            // ...getColumnSortByDateProps('createdAt')

        },
        {
            title: 'État',
            dataIndex: 'usesLeft',
            key: 'usesLeft',
            render: (data) => (<Badge color={data === 0 ? "#52C41A" : "#FAAD14"} text={data === 0 ? "Activé" : "Non Activé"} />),
            width: "100px",
            ...getColumnFilterStatusProps()
        }

    ];

    return (
        <Content
            className="site-layout-background"
            style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
            }}
        >
            <PageHeader
                title={"Historique des Codes d’Accès"}
                breadcrumbItems={["Codes d’Accès", "Historique des Codes d’Accès"]}
            />
            <div
                style={{
                    margin: "2em",
                    padding: "1em 2em 1em 2em",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    backgroundColor: "#fff"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <h3>Liste des Codes d’Accès</h3>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems: "center"
                    }}>
                        <p>Filtrer par client : </p>
                        <Select
                            style={{
                                backgroundColor: "#39B13D",
                                marginRight: "28px",
                                marginLeft: "8px",
                                color: "#fff",
                                height: "2.3em",
                                width: 200,
                                fontWeight: "400"
                            }}
                            options={nameOptions}
                            onChange={(e) => { setFilterData({ ...filterData, clientPro: e }) }}
                        >
                        </Select>

                        <p style={{ marginRight: "10px", fontSize: "15px" }}>Filtrer par date :</p>
                        <RangePicker
                            style={{
                                height: "2.5em"
                            }}
                            onCalendarChange={onChangeDates}
                        />
                    </div>
                </div>
                <Divider style={{ margin: "10px 0px 10px 0px" }} />
                <Table
                    loading={isLoadingCodesList}
                    dataSource={CodesList?.data}
                    pagination={{
                        total: CodesList?.totalCount,
                        pageSize: filterData.limit,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 15, 20, 30, 40, 50],
                        showQuickJumper: true,
                        showTotal: (total, range) => (<>Nombre de codes : {total} </>),
                    }}
                    columns={columns}
                    onChange={onChangePage}
                />
            </div>


        </Content>
    )
}